<template>
	<!-- 支付方式 -->
	<div class="page-all">
		<div class="page-message">
			<div class="message">
				<div>订单已提交！请您尽快支付！</div>
				<div>订单号：{{resData.order_sn}}</div>
				<div>支付金额：<span style="color: #F83850;">￥{{resData.total_money}}</span></div>
			</div>
		</div>
		<!-- 支付方式 -->
		<div class="contentTitle">支付方式</div>
		<div :class="['wxPay',choosePay==1?'wxPayIn':'']" @click="toChoosePay()">
			<img src="../../../../assets/img/organize/wx.png" class="wxIcon">
			微信
			<img src="../../../../assets/img/organize/ok.png" class="okBg" v-show="choosePay==1">
		</div>
		<div :class="['zfbPay',choosePay==2?'zfbPayIn':'']" @click="tochoosePayc()">
			<img src="../../../../assets/img/organize/zfb.png" class="wxIcon">
			支付宝
			<img src="../../../../assets/img/organize/ok.png" class="okBg1" v-show="choosePay==2">
		</div>
		<!-- 二维码 -->
		<div class="contentPicture">
			<div class="payUp">
				<img src="../../../../assets/img/organize/wx.png" v-show="choosePay==1" class="wxIcon">
				<img src="../../../../assets/img/organize/zfb.png" v-show="choosePay==2" class="wxIcon">
				<span v-show="choosePay==1">微信支付</span>
				<span v-show="choosePay==2">支付宝支付</span>
			</div>
			<img class="payCenter" :src="url" v-show="choosePay==1"></img>
			<div class="paydown" v-show="choosePay==1">请打开手机微信，扫一扫完成支付</div>
			<div class="paydown" v-show="choosePay==2" @click="toChoosePay1()">请打开支付宝支付页面，扫一扫完成支付</div>
		</div>
		<!-- 支付宝支付 -->
		<div class="zhifubao" v-show="choosePay==2" @click="choosePay=1">
			<iframe id="zhifubao" class="zhifubaoframe"></iframe>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				// 支付方式(微信支付1 支付宝支付2)
				choosePay: 1,
				resData: '',
				timer: '',
				paydata: {},
				value: 1,
				zhifubao: "",
				url: ""
			};
		},
		created() {
			if (localStorage.getItem("zhifubao")) {
				this.$route.query.data = JSON.parse(localStorage.getItem("zhifubao"))
				this.$route.query.data.payment = 1
			}
			this.resData = this.$route.query.data
			if (this.resData.payment) {
				this.choosePay = this.resData.payment
			}
			this.tocomplete(this.$route.query.data.order_sn)
		},
		watch: {
			'$route': function() {
				this.tocomplete(this.$route.query.data.order_sn)
			},
		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.$cookieStore.delCookie("laravel_session")
			this.$cookieStore.delCookie("XSRF-TOKEN")
			localStorage.removeItem("zhifubao")
		},
		mounted() {
			this.starttime()
		},
		methods: {
			starttime() {
				var that = this
				this.timer = setInterval(() => {
					that.checkpay(that.$route.query.data.order_sn);
				}, 3000);
			},
			//清楚支付宝cookie
			deletezfb() {
				var cookies = document.cookie.split(";");
				var domain = '.' + location.host;
				for (var i = 0; i < cookies.length; i++) {
					var cookie = cookies[i];
					var eqPos = cookie.indexOf("=");
					var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
					document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=" + domain + "; path=/";
				}
				if (cookies.length > 0) {
					for (var i = 0; i < cookies.length; i++) {
						var cookie = cookies[i];
						var eqPos = cookie.indexOf("=");
						var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
						document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=" + domain + "; path=/";
					}
				}
			},
			// 下一步按钮
			async tocomplete(ordersn) {
				this.$get("member/getpay/", {
					ordersn: ordersn,
					type: this.choosePay, //1:微信支付二维码//2:支付宝二维码
				}).then(response => {
					if (this.choosePay == 2) {
						const div = document.createElement('div')
						div.innerHTML = response
						//document.getElementById("zhifubao").contentWindow.document.designMode = "on";
						//document.getElementById("zhifubao").contentWindow.document.contentEditable = true;
						//document.getElementById("zhifubao").contentWindow.document.body.appendChild(div);
						//document.getElementById("zhifubao").contentWindow.document.forms[0].submit()
						document.body.appendChild(div)
						document.forms[0].submit()
						localStorage.setItem("zhifubao", JSON.stringify(this.$route.query.data))
						return
					}
					if (response.error == 0) {
						if (this.choosePay == 1) {
							this.paydata = response.data.payurl
							this.url = "https://" + response.data.payurl.paycode_url
							return
						}
					} else {
						//this.$message.error(response.msg)
						return
					}
				});
			},
			toNext(e) {
				this.$router.push({
					path: '/paySuccess',
					query: {
						data: e
					}
				})
			},
			checkpay(ordersn) {
				this.value++
				this.$get("order/checkpay", {
					ordersn: ordersn,
				}).then(response => {
					if (response.error == 0) {
						this.toNext(response.data)
					} else {

						return
					}
				});
			},
			// 选择支付方式
			toChoosePay() {
				this.choosePay = 1
				this.tocomplete(this.$route.query.data.order_sn)
				this.starttime()
			},
			tochoosePayc() {
				this.choosePay = 2
				//clearInterval(this.timer);
				this.tocomplete(this.$route.query.data.order_sn)
			},
			toChoosePay1() {
				this.choosePay = 2

				// this.zhifubao = "https://ykt.qdy2020.com/member/getpay/?ordersn="+this.$route.query.data.order_sn+"&type=2"
				// window.open(this.zhifubao, '_blank');
			},
		}
	};
</script>

<style scoped>
	.page-all {
		width: 70%;
		height: auto;
		margin: 0 auto;
		position: relative;
		top: 40px;
		margin-bottom: 52px;
		background-color: white;
		overflow: hidden;
		padding: 30px 20px;
	}

	.page-message {
		width: 100%;
		height: 160px;
		border: 1px solid #E6E6E6;
	}

	.message {
		width: 250px;
		height: auto;
		margin: 0 auto;
	}

	.message div:nth-child(1) {
		font-size: 16px !important;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-top: 29px;
	}

	.message div:nth-child(2) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 26px;
	}

	.message div:nth-child(3) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 12px;
	}

	.contentTitle {
		width: 100%-20px;
		height: 46px;
		background: #F6F6F6;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		padding-left: 20px;
		margin: 20px 0;
	}

	.wxPay {
		display: inline-block;
		width: 140px;
		height: 46px;
		border: 1px solid #E6E6E6;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		margin-left: 20px;
		margin-bottom: 30px;
	}

	.wxPayIn {
		display: inline-block;
		width: 140px;
		height: 46px;
		border: 1px solid #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		margin-left: 20px;
		margin-bottom: 30px;
	}

	.wxIcon {
		float: left;
		margin-left: 24px;
		margin-top: 10px;
		margin-right: 7px;
		width: 29px;
		height: 25px;
	}

	.okBg {
		position: absolute;
		margin-top: 22px;
		width: 25px;
		height: 25px;
		margin-left: 30px;
	}

	.okBg1 {
		position: absolute;
		margin-top: 22px;
		width: 25px;
		height: 25px;
		margin-left: 14px;
	}

	.zfbPay {
		display: inline-block;
		width: 140px;
		height: 46px;
		border: 1px solid #E6E6E6;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		margin-left: 20px;
	}

	.zfbPayIn {
		display: inline-block;
		width: 140px;
		height: 46px;
		border: 1px solid #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 46px;
		margin-left: 20px;
	}

	.contentPicture {
		width: 280px;
		height: 400px;
		margin: 0 auto;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 42px;
	}

	.payUp {
		margin: 0 auto;
		width: 150px;
		height: 50px;
	}

	.payCenter {
		width: 253px;
		height: 253px;
		margin: 0 auto;
		margin-left: 12px;
		background-color: #000000;
	}

	.paydown {
		width: 256px;
		height: 50px;
		background: #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 50px;
		text-align: center;
		margin: 0 auto;
		margin-top: 7px;

	}

	.zhifubao {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 300;
		font-family: Source Han Sans CN !important;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.zhifubaoframe {
		background: #FFFFFF;
		height: 500px;
		width: 1200px;
	}
</style>
